import React, { Component } from "react"

export class BannerAfterMainSection extends Component {
    render() {
        return (
            <div>
                <div className="bg-newlndgray pt-[1670px] md:pt-[660px] lg:pt-[530px] 2xl:pt-[440px]">
                    <div className="grid md:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 px-10 pt-14 pb-8 mx-auto 2xl:flex 2xl:flex-row items-start p-0 gap-8 w-full sm:justify-center">
                        {this.props.homePageData[0].node.bannerAfterMainSectionHeaderList.map((content, index) => (
                            <div key={content.content} className="flex flex-row items-start p-0 gap-2 max-w-xl sm:w-[190.4px] h-auto text-center">
                                <div className="w-[4px] h-auto bg-[#F3F6FB] flex-none order-0 self-stretch flex-grow-0"></div>
                                <div className="flex flex-col items-start p-0 gap-2 w-[186.4px] h-auto flex-none order-1 flex-grow">
                                    <div className="max-w-xl sm:w-[186.4px] h-auto text-[#655C5A] font-inter font-normal text-sm leading-4 tracking-wide flex-none order-1 self-stretch flex-grow-0">
                                        {content.content}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="container mx-auto pt-10 lg:pt-16">
                    <div className="px-5 mx-auto">
                        <p className="text-lndred text-sm text-center lg:text-left xl:px-24 2xl:px-[192px]">Maximize Value</p>
                        <p className="font-bold text-2xl text-center lg:text-left xl:px-24 2xl:px-[190px] lg:text-3xl">
                            {this.props.homePageData[0].node.bannerAfterMainSectionTitle}
                        </p>

                        <div className="text-center lg:text-left xl:px-24 2xl:px-[190px] pt-4" dangerouslySetInnerHTML={{
                            __html: this.props.homePageData[0].node.bannerAfterMainSectionContent.childMarkdownRemark.html,
                        }} />

                        <div className="mx-auto pt-7 xl:px-24 2xl:px-[190px]">
                            <div className="bg-newlndgray p-5">
                                <div className="py-3 px-5">
                                    <ul className="space-y-3 list-decimal marker:text-lndred marker:font-bold">
                                        {this.props.homePageData[0].node.bannerAfterMainSectionList.map(content =>
                                            <div key={content.content}>
                                                <li>{content.content}</li>
                                            </div>
                                        )}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}