import * as React from "react"
import Layout from "../components/layout"
import { graphql, useStaticQuery } from "gatsby"
import Seo from "../components/seo"
import { ContactForm } from "../components/contact-form"
import { BannerBottom } from "../components/banner-bottom"
import MainSection from "../components/main-section"
// import { BannerSteps } from "../components/banner-steps"
import { GridSevenHomepage } from "../components/grid-seven-home-page"
import Video from "../components/video"
import CookiePopup from "../components/popup-cookie"
import GoogleReviews from "../components/reviews"
import { BannerAfterMainSection } from "../components/banner-after-main-section"

const IndexPage = (props) => {
  let keywords = [
    "Seat Belt Repair",
    "Seat Belt Coloring",
    "Battery Cable Repair",
    "Steering Column Sensor Repair",
    "SRS Airbag Module Repair",
    "Convertible Roll Bar Repair"
  ]
  const data = useStaticQuery(graphql`
    query{
        allContentfulHomePage {
            edges {
              node {
                section1Body {
                  childMarkdownRemark {
                    html
                  }
                }
                section1BodyMobile1 {
                    childMarkdownRemark {
                      html
                    }
                  }
                  section1BodyMobile2 {
                    childMarkdownRemark {
                      html
                    }
                  }
                  bannerAfterMainSectionHeaderList {
                    content
                  }
                  bannerAfterMainSectionTitle
                  bannerAfterMainSectionContent {
                    childMarkdownRemark {
                      html
                    }
                  }
                  bannerAfterMainSectionList {
                    content
                  }
                  videoUrl
                  bottomBannerTitle
                  bottomBannerBody {
                    childMarkdownRemark {
                      html
                    }
                  }
                  bannerImage {
                    gatsbyImageData
                  }
              }
            }
          }
          allContentfulSingleStageSeatBeltRepairService {
            edges {
              node {
                image {
                  gatsbyImageData(quality: 100, placeholder: NONE)
                  description
                  title
                }
                description {
                  childMarkdownRemark {
                    html
                  }
                }
                servicePageBodyText {
                  childMarkdownRemark {
                    html
                  }
                }
              }
            }
          }
          allContentfulDualStageSeatBeltRepairService {
            edges {
              node {
                image {
                  gatsbyImageData(quality: 100, placeholder: NONE)
                  description
                  title
                }
                serviceCardDescription {
                  childMarkdownRemark {
                    html
                  }
                }
                servicePageBodyText {
                  childMarkdownRemark {
                    html
                  }
                }
              }
            }
          }
          allContentfulTripleStageSeatBeltRepairService {
            edges {
              node {
                image {
                  gatsbyImageData(quality: 100, placeholder: NONE)
                  description
                  title
                }
                serviceCardDescription {
                  childMarkdownRemark {
                    html
                  }
                }
                servicePageBodyText {
                  childMarkdownRemark {
                    html
                  }
                }
              }
            }
          }
          allContentfulPretensionerService {
            edges {
              node {
                image {
                  gatsbyImageData(quality: 100, placeholder: NONE)
                  description
                  title
                }
                serviceCardDescription {
                  childMarkdownRemark {
                    html
                  }
                }
                servicePageBodyText {
                  childMarkdownRemark {
                    html
                  }
                }
              }
            }
          }
          allContentfulSeatBeltColoringService {
            edges {
              node {
                image {
                  gatsbyImageData(quality: 100, placeholder: NONE)
                  description
                  title
                }
                serviceCardDescription {
                  childMarkdownRemark {
                    html
                  }
                }
                servicePageBodyText {
                  childMarkdownRemark {
                    html
                  }
                }
              }
            }
          }
          allContentfulBatteryCableRepairService {
            edges {
              node {
                image {
                  gatsbyImageData(quality: 100, placeholder: NONE)
                  description
                  title
                }
                serviceCardDescription {
                  childMarkdownRemark {
                    html
                  }
                }
                servicePageBodyText {
                  childMarkdownRemark {
                    html
                  }
                }
              }
            }
          }
          allContentfulSteeringColumnSensorRepairService {
            edges {
              node {
                image {
                  gatsbyImageData(quality: 100, placeholder: NONE)
                  description
                  title
                }
                serviceCardDescription {
                  childMarkdownRemark {
                    html
                  }
                }
                servicePageBodyText {
                  childMarkdownRemark {
                    html
                  }
                }
              }
            }
          }
          allContentfulSrsAirbagModuleRepairService {
            edges {
              node {
                image {
                  gatsbyImageData(quality: 100, placeholder: NONE)
                  description
                  title
                }
                serviceCardDescription {
                  childMarkdownRemark {
                    html
                  }
                }
                servicePageBodyText {
                  childMarkdownRemark {
                    html
                  }
                }
              }
            }
          }
          allContentfulConvertibleRollBarService {
            edges {
              node {
                image {
                  gatsbyImageData(quality: 100, placeholder: NONE)
                  description
                  title
                }
                serviceCardDescription {
                  childMarkdownRemark {
                    html
                  }
                }
                servicePageBodyText {
                  childMarkdownRemark {
                    html
                  }
                }
              }
            }
          }    
        mainImg: file(relativePath: { eq: "home-bg.png" }) {
            childImageSharp {
                gatsbyImageData(
                    quality: 100,
                    placeholder: NONE,
                )
            }
        }
        mainImgSm: file(relativePath: { eq: "home-car.png" }) {
            childImageSharp {
                gatsbyImageData(
                    quality: 100,
                    placeholder: NONE,
                )
            }
        }
        seatBeltRepairImg: file(relativePath: { eq: "seatbelt-repair.png" }) {
            childImageSharp {
                gatsbyImageData(
                    quality: 100,
                    placeholder: NONE,
                )
            }
        }
        seatBeltColorImg: file(relativePath: { eq: "seatbelt-color.png" }) {
            childImageSharp {
                gatsbyImageData(
                    quality: 100,
                    placeholder: NONE,
                )
            }
        }
        batteryCableImg: file(relativePath: { eq: "battery.png" }) {
            childImageSharp {
                gatsbyImageData(
                    quality: 100,
                    placeholder: NONE,
                )
            }
        }
        steeringColumnImg: file(relativePath: { eq: "steering-column.png" }) {
            childImageSharp {
                gatsbyImageData(
                    quality: 100,
                    placeholder: NONE,
                )
            }
        }
        srsImg: file(relativePath: { eq: "srs.png" }) {
            childImageSharp {
                gatsbyImageData(
                    quality: 100,
                    placeholder: NONE,
                )
            }
        }
        rollBarImg: file(relativePath: { eq: "rollbar.png" }) {
            childImageSharp {
                gatsbyImageData(
                    quality: 100,
                    placeholder: NONE,
                )
            }
        }
        headrestImg: file(relativePath: { eq: "headrest.png" }) {
            childImageSharp {
                gatsbyImageData(
                    quality: 100,
                    placeholder: NONE,
                )
            }
        }
    }
  `)
  const homePageData = data.allContentfulHomePage.edges
  return (
    <div>
      <Layout>
        <Seo
          title="Post Collision Specialists - L&D Solutions Inc."
          location={props.location.pathname}
          keywords={keywords}
        />
        <CookiePopup />
        <MainSection
          title="Save Money With Seat Belt Repair & SRS Airbag Module Reset Service"
          body={homePageData[0].node.section1Body.childMarkdownRemark.html}
          bodyMobile1={homePageData[0].node.section1BodyMobile1.childMarkdownRemark.html}
          bodyMobile2={homePageData[0].node.section1BodyMobile2.childMarkdownRemark.html}
          buttonPage="/services" buttonText="View All Services"
          mainImage={data.mainImg.childImageSharp.gatsbyImageData}
          mainImageSm={data.mainImgSm.childImageSharp.gatsbyImageData}
          seatBeltRepairImg={data.seatBeltRepairImg.childImageSharp.gatsbyImageData}
          seatBeltColorImg={data.seatBeltColorImg.childImageSharp.gatsbyImageData}
          batteryCableImg={data.batteryCableImg.childImageSharp.gatsbyImageData}
          steeringColumnImg={data.steeringColumnImg.childImageSharp.gatsbyImageData}
          srsImg={data.srsImg.childImageSharp.gatsbyImageData}
          rollBarImg={data.rollBarImg.childImageSharp.gatsbyImageData}
          headrestImage={data.headrestImg.childImageSharp.gatsbyImageData}
          btnTitle="View All Services"
          btnAlt="View All Services"
          homePage
          homePageData={data.allContentfulHomePage.edges}
        />
        <BannerAfterMainSection homePageData={data.allContentfulHomePage.edges} />
        <div className="lg:pt-14 lg:pb-14 2xl:pb-20 py-10 mx-auto text-center">
          <p className="font-bold text-2xl lg:text-3xl md:text-2xl">Welcome to L&D Solutions!</p>
          <div className="flex justify-center pt-5">
            <Video
              videoSrcURL={homePageData[0].node.videoUrl}
              videoTitle="Welcome to L&D Solutions!"
            />
          </div>
        </div>
        {/* <BannerSteps homePageData={data.allContentfulHomePage.edges} /> */}
        <GridSevenHomepage title="Post Collision Services We Offer"
          singleStage={data.allContentfulSingleStageSeatBeltRepairService.edges}
          dualStage={data.allContentfulDualStageSeatBeltRepairService.edges}
          tripleStage={data.allContentfulTripleStageSeatBeltRepairService.edges}
          pretensioner={data.allContentfulPretensionerService.edges}
          seatBeltColor={data.allContentfulSeatBeltColoringService.edges}
          batteryCable={data.allContentfulBatteryCableRepairService.edges}
          steeringColumn={data.allContentfulSteeringColumnSensorRepairService.edges}
          srsAirbag={data.allContentfulSrsAirbagModuleRepairService.edges}
          convertibleRoll={data.allContentfulConvertibleRollBarService.edges}
        />
        <GoogleReviews />
        <BannerBottom bannerData={homePageData[0].node} />
        <div className="px-5 py-10 pb-20">
          <ContactForm formName="Home Page" />
        </div>
      </Layout>
    </div>
  )
}
export default IndexPage